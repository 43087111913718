import logo from './logo.svg';
import './App.css';
import ShortUrl from './component/shorturl.jsx'

import 'element-theme-default';

function App() {
  return (
    <div className="App">
      <ShortUrl />
    </div>
  );
}

export default App;
