import './shorturl.css';
import React from 'react';
import { Button, Input } from 'element-react';

import 'element-theme-default';
import axios from 'axios';

class ShortUrl extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            long_url: '',
            short_url: ''
        }
        this.transformShort = this.transformShort.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.changeLongUrl = this.changeLongUrl.bind(this);
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    resetInput() {
        console.log('resetInput');
        this.setState({long_url: '', short_url: ''});
    }
    transformShort() {
        const url = 'https://dl.jdsctec.com/cr/?lurl='+this.state.long_url;
        axios.put(url).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({short_url: data.short_url});
        })
    }
    changeLongUrl(event) {
        this.setState({long_url: event});
    }
    render() {
        const {
            ...componentProps
        } = this.props;
        return (
            <div className="content">
                <div className="query">
                    <Input placeholder="输入待转换长链接" value={this.state.long_url} onChange={this.changeLongUrl}/>
                    <Button type="primary" onClick={this.transformShort}>生成</Button>
                </div>
                <div className="result">
                    <span>{this.state.short_url}</span>
                    <a href={this.state.short_url} target="_blank">短链跳转</a>
                </div>
            </div>
        )
    }
}

ShortUrl.propTypes = {

}

export default ShortUrl;